import React from 'react';
import { useSelector } from 'react-redux';

export type TranslateBtnWrapperProps = {
    children: React.ReactNode;
};

export const TranslateBtnWrapper = (props: TranslateBtnWrapperProps) => {
    const features = useSelector((state: any) => state.app.features);
    const displayTranslateBtn = features?.translations?.user_content;
    const { children } = props;
    return <>{displayTranslateBtn && children}</>;
};
