export default {
  "repliedToYourComment": "Ha risposto al tuo commento",
  "likedYourComment": "Mi è piaciuto il tuo commento",
  "sentYou": "Ti ho inviato {{text, en-handle-an}}",
  "received": "Ricevuto {{text, en-handle-an}}",
  "sendEmail": "Posta elettronica",
  "sendMessage": "Messaggio",
  "createThread": "Crea nuovo thread",
  "attachments": "Allegati",
  "done": "Fatto",
  "expand": "Espandi",
  "hide": "Nascondi",
  "showMoreComments": "Mostra altri commenti",
  "hideComments": "Nascondi commenti",
  "showComments": "Mostra commenti",
  "hideReplies": "Nascondi risposte",
  "showReplies": "Mostra risposte",
  "deleteComment": "Elimina commento",
  "deleteCommentDesc": "Sei sicuro di voler rimuovere questo commento?",
  "noCommentsYet": "Non ci sono ancora commenti...",
  "chooseAction": "Scegli un'azione",
  "deletePost": "Elimina",
  "deletePostDesc": "Sei sicuro di voler rimuovere questo post?",
  "unfollow": "Smetti di seguire",
  "snooze": "Snooze",
  "reportPost": "Segnala post",
  "report": "Rapporto",
  "reportReason": "Motivo della segnalazione di questo post",
  "messageToManager": "Messaggio al manager",
  "delete": "Elimina",
  "emptyAwardsMessage": "Non c'è nulla da mostrare qui in questo momento perché non hai ancora ricevuto nulla!",
  "Followers": "Seguaci",
  "Following": "Seguendo",
  "SendAThankYou": "Invia {{label, en-handle-an}}",
  "SeeAll": "Vedi tutto",
  "SentToYou": "Inviato a te",
  "CreateASocialPost": "Crea un post sui social",
  "Photo": "Foto",
  "Video": "Video",
  "Files": "File",
  "Post": "Posta",
  "LatestComments": "Ultimi commenti",
  "SentBy": "Inviato Da",
  "AllPosts": "Tutto",
  "MyGroups": "I Miei Gruppi",
  "Search": "Cercare...",
  "SpecificGroup": "Gruppo Specifico",
  "selectGroupToPost": "Seleziona un gruppo per postare...",
  "cannotPostAlert": "Sembra che tu non abbia il permesso di postare in questo gruppo. Si prega di selezionare un altro gruppo.",
  "translateDescription": "Tradurre nella tua lingua preferita",
  "seeOriginalDescription": "Vedi il testo originale",
  "snoozeDescription": "Smetti di vedere temporaneamente i post di {{name}}",
  "unfollowDescription": "Smetti di vedere i post di {{name}}",
  "reportDescription": "Hai un problema con questo post?",
  "deleteDescription": "Eliminare definitivamente questo post?"
};
