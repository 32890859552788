import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { SearchField } from '../../../../components/searchField/searchField';
import { useSearchHook } from '../../../../hooks/useSearchHook';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { AlphabetList } from '../../../../components/alphabetList/alphabetList';
import { GroupsList } from '../../../../modules/GroupsList/GroupsList';
import { useGroupListQuery } from '../../../../hooks/query/useGroupListQuery';
import { useDebounce } from '../../../../hooks/useDebounceHook';
import { NoResultsWidget } from '../../../../components/noResultWidget/noResultWidget';
import SpinnerLoad from '../../../../components/common/spinnerLoad/spinnerLoad';
import { GroupItem } from '../../../../modules/GroupItem/GroupItem';
import { MembersListModal } from '../../../../modules/MembersListModal/MembersListModal';
import { useGroupMembersQuery } from '../../../../hooks/query/useGroupMembersQuery';
import { useJoinOrLeaveGroupQuery } from '../../../../hooks/query/useJoinOrLeaveGroupQuery';
import { useQueryClient } from 'react-query';

export const Groups = () => {
    const { searchQuery, handleChange: handleSearch } = useSearchHook('');
    const { t } = useTranslation();
    const { debouncedValue: debouncedSearchValue } = useDebounce(searchQuery);
    const { id: groupID } = useParams();
    const [alphabetFilter, setAlphabetFilter] = useState<string>('');
    const { searchQuery: searchQueryMembers, handleChange: handleSearchMembers } = useSearchHook('');
    const { debouncedValue: debouncedSearchValueMembers } = useDebounce(searchQueryMembers);
    const [displayMembersModal, setDisplayMembersModal] = useState<{
        open: boolean;
        id: string;
    }>({
        open: false,
        id: '',
    });
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {
        data: groups,
        isLoading: loadingGroups,
        fetchNextPage,
        isFetchingNextPage,
    } = useGroupListQuery({
        searchQuery: debouncedSearchValue,
        prefix: alphabetFilter,
    });
    const { mutate: joinOrLeaveGroup } = useJoinOrLeaveGroupQuery(() => {
        refetchMembersList();
    });
    const {
        data: groupMembers,
        isLoading: loadingGroupMembers,
        fetchNextPage: fetchNextPageGroupMembers,
        isFetchingNextPage: isFetchingNextPageGroupMembers,
        refetch: refetchMembersList,
    } = useGroupMembersQuery({
        searchQuery: debouncedSearchValueMembers,
        id: displayMembersModal.id || '',
    });

    const groupData =
        groups?.pages
            ?.map((page) => page?.data)
            .flat()
            .find((group) => group.id === Number(groupID)) || [];

    const handleJoinOrLeaveGroup = (id: number, is_member: boolean) => {
        queryClient.setQueryData(['groups-list', debouncedSearchValue, alphabetFilter], (prevData: any) => {
            if (!prevData) return;
            return {
                ...prevData,
                pages: prevData.pages.map((page: any) => ({
                    ...page,
                    data: page.data.map((group: any) => {
                        if (group.id === id) {
                            return {
                                ...group,
                                is_member: !is_member,
                                can_leave: !is_member,
                                user_count: is_member ? group.user_count - 1 : group.user_count + 1,
                            };
                        }
                        return group;
                    }),
                })),
            };
        });
        joinOrLeaveGroup({ groupID: id, type: is_member ? 'leave' : 'join' });
    };

    const handleAlphabetClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const char = e.currentTarget.getAttribute('data-char') || '';
        setAlphabetFilter((prev: string) => (prev === char ? '' : char));
    };

    const handleScroll = (e: any) => {
        let bottom = Math.round(e.target.scrollTop + e.target.clientHeight) + 1 >= e.target.scrollHeight;
        if (bottom && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    const onMembersClick = (id: number) => {
        setDisplayMembersModal({
            open: true,
            id: id.toString(),
        });
    };

    const handleGroupCardClick = (id: number) => {
        navigate(`/connect/groups/${id}`);
    };

    const handleScrollMembers = (e: any) => {
        let bottom = Math.round(e.target.scrollTop + e.target.clientHeight) + 1 >= e.target.scrollHeight;
        if (bottom && !isFetchingNextPageGroupMembers) {
            fetchNextPageGroupMembers();
        }
    };

    return (
        <Grid container data-testid='groups-page' sx={{ margin: 0, flexDirection: 'column', gap: '15px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4} xl={4} sx={{ paddingRight: groupID ? '14px' : '4px' }}>
                    <SearchField
                        searchQuery={searchQuery}
                        handleSearch={handleSearch}
                        placeholder={t('connect.search') as string}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            borderRadius: '8px',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            height: '35px',
                        }}
                        iconStyle={{
                            padding: '0 10px',
                            fontSize: '16px',
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {groups?.pages?.[0].data.length === 0 && !loadingGroups ? (
                    <Grid item xs={12} sx={{ height: '65vh', display: 'flex', justifyContent: 'center' }}>
                        <NoResultsWidget
                            title={t('connect.noResultsFound')}
                            containerSx={{
                                flexGrow: 1,
                            }}
                            description={
                                t(debouncedSearchValue ? 'connect.noSearchResultsGroupsDescription' : 'connect.noGroupsResultsDescription') as string
                            }
                            descriptionSx={{
                                textAlign: 'center',
                                maxWidth: '450px',
                            }}
                        />
                        <AlphabetList
                            containerProps={{
                                sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '75vh',
                                    alignItems: 'center',
                                    paddingRight: '10px',
                                    margin: '5px 0 0',
                                },
                            }}
                            selectedAlphabet={alphabetFilter}
                            handleClick={handleAlphabetClick}
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={groupID ? 4 : 12} sx={{ display: 'flex' }}>
                            <Box
                                sx={{ height: '75vh', flexGrow: '1', overflow: 'hidden', overflowY: 'scroll', paddingBottom: '5px' }}
                                onScroll={handleScroll}
                            >
                                <Grid container spacing={2} data-testid='groups-list' sx={{ paddingRight: '16px' }}>
                                    <GroupsList
                                        onMembersClick={onMembersClick}
                                        onJoinOrLeaveGroupClick={handleJoinOrLeaveGroup}
                                        loading={loadingGroups}
                                        groups={groups}
                                        containerProps={{
                                            xs: 12,
                                            md: groupID ? 12 : 6,
                                            lg: groupID ? 12 : 4,
                                        }}
                                        groupID={groupID as string}
                                        handleGroupCardClick={handleGroupCardClick}
                                    />
                                    ;
                                    <Grid item xs={12}>
                                        {isFetchingNextPage && <SpinnerLoad className='my-2' />}
                                    </Grid>
                                </Grid>
                            </Box>
                            <AlphabetList
                                containerProps={{
                                    sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '75vh',
                                        alignItems: 'center',
                                        paddingRight: '10px',
                                        margin: '5px 0 0',
                                    },
                                }}
                                selectedAlphabet={alphabetFilter}
                                handleClick={handleAlphabetClick}
                            />
                        </Grid>
                        {groupID && (
                            <Grid item xs={8} data-testid='group-list-details-page'>
                                <GroupItem
                                    id={groupID}
                                    onMembersClick={onMembersClick}
                                    displayButton={true}
                                    hideScrollBar={false}
                                    userCount={groupData?.user_count}
                                    loading={loadingGroups}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            <MembersListModal
                open={displayMembersModal.open}
                memberList={groupMembers?.pages?.map((page) => page?.data).flat() || []}
                handleModalClose={() => {
                    setDisplayMembersModal((prev) => ({ ...prev, open: false }));
                }}
                loading={loadingGroupMembers}
                handleScroll={handleScrollMembers}
                fetchingNextPage={isFetchingNextPageGroupMembers}
                fetchNextPage={fetchNextPageGroupMembers}
                header={t('connect.groupMembers') as string}
                displaySearchField
                searchFieldProps={{
                    placeholder: t('general.search') as string,
                    searchQuery: searchQueryMembers,
                    handleSearch: handleSearchMembers,
                }}
                totalMembers={groupMembers?.pages[0]?.meta?.total || 0}
            />
        </Grid>
    );
};
