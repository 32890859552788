import { FeaturesReducerState } from '../../../types/types';

export const FEATURES_STATE = "FEATURES_STATE";

export function setFeaturesState(featuresData: FeaturesReducerState){
    return {
        type: FEATURES_STATE,
        payload: featuresData
    }
}

type SetFeaturesAction = {
    type: typeof FEATURES_STATE,
    payload: FeaturesReducerState;
} 


export type FeaturesActionTypes = SetFeaturesAction;