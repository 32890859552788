export default {
  "yes": "Sim",
  "no": "Não",
  "ok": "OK",
  "cancel": "Cancelar",
  "hour": "Hora",
  "hours": "Horas",
  "week": "Semana",
  "month": "Mês",
  "year": "Ano",
  "tyCard": "Cartão de Agradecimento",
  "tyCards": "Cartões de Agradecimento",
  "send": "Enviar",
  "public": "Público",
  "private": "Privado",
  "like": "Gosto",
  "likes": "Gostos",
  "comment": "Comentário",
  "comments": "Comentários",
  "reply": "Responder",
  "share": "Partilhar",
  "person": "pessoa",
  "people": "pessoas",
  "selected": "selecionada",
  "search": "Procurar",
  "errors": {
    "errorLoadingData": "Erro ao carregar os dados",
    "errorLoadingPage": "Erro ao carregar a página",
    "errorReload": "Erro ao carregar o menu, recarregue a página"
  },
  "success": {
    "general": "Action was successful"
  },
  "connect": {
    "locations": "Localizações",
    "contacts": "Contactos",
    "favourites": "Favourites",
    "all": "Todos",
    "nodatafound": "No data found",
    "thereAreNoPost": " There are no social posts, start posting to see them here!",
    "awarded": "Awarded",
    "expires": "Expires",
    "contactDetails": "Dados de Contacto",
    "followers": "Followers",
    "following": "Following",
    "unfollow": "Unfollow",
    "unsnooze": "Unsnooze",
    "snooze": "Snooze",
    "1Hour": "1 Hour",
    "8Hours": "8 Hours",
    "24Hours": "24 Hours",
    "1Week": "1 Week",
    "1Month": "1 Month",
    "message": "Mensagem",
    "details": "Detalhes",
    "thankYou": "Obrigado",
    "posts": "Posts"
  },
  "noData": "Parece que ainda não há nada aqui!",
  "noDataDescription": "Volte mais tarde para ver as atualizações.",
  "members": "Membros",
  "translate": "Traduzir",
  "seeOriginal": "Ver original"
};