export default {
  "repliedToYourComment": "Heeft op je reactie gereageerd",
  "likedYourComment": "Ik vond je reactie leuk",
  "sentYou": "Ik heb je {{text, en-handle-an}} gestuurd",
  "received": "{{text, en-handle-an}} ontvangen",
  "sendEmail": "Email",
  "sendMessage": "Bericht",
  "createThread": "Nieuwe thread aanmaken",
  "attachments": "Bijlagen",
  "done": "Klaar",
  "expand": "Uitbreiden",
  "hide": "Verstoppen",
  "showMoreComments": "Meer reacties weergeven",
  "hideComments": "Reacties verbergen",
  "showComments": "Opmerkingen weergeven",
  "hideReplies": "Antwoorden verbergen",
  "showReplies": "Antwoorden weergeven",
  "deleteComment": "Commentaar verwijderen",
  "deleteCommentDesc": "Weet je zeker dat je deze reactie wilt verwijderen?",
  "noCommentsYet": "Er zijn nog geen reacties...",
  "chooseAction": "Kies een actie",
  "deletePost": "Verwijderen",
  "deletePostDesc": "Weet je zeker dat je dit bericht wilt verwijderen?",
  "unfollow": "Niet meer volgen",
  "snooze": "Snooze",
  "reportPost": "Bericht melden",
  "report": "Rapporteren",
  "reportReason": "Reden voor het melden van dit bericht",
  "messageToManager": "Bericht aan manager",
  "delete": "Verwijderen",
  "emptyAwardsMessage": "Er is hier op dit moment niets te laten zien, want je hebt nog niets ontvangen!",
  "Followers": "Volgers",
  "Following": "Volgen",
  "SendAThankYou": "Verstuur {{label, en-handle-an}}",
  "SeeAll": "Alles bekijken",
  "SentToYou": "Naar jou verzonden",
  "CreateASocialPost": "Maak een social post",
  "Photo": "Foto",
  "Video": "Video",
  "Files": "bestanden",
  "Post": "Post",
  "LatestComments": "Laatste reacties",
  "SentBy": "Verzonden Door",
  "AllPosts": "Alle",
  "MyGroups": "Mijn Groepen",
  "Search": "Zoekopdracht...",
  "SpecificGroup": "Specifieke Groep",
  "selectGroupToPost": "Selecteer een groep om naar te posten...",
  "cannotPostAlert": "Het lijkt erop dat je geen toestemming hebt om in deze groep te posten. Kies alstublieft een andere groep.",
  "translateDescription": "Vertalen naar je voorkeurstaal",
  "seeOriginalDescription": "Bekijk de oorspronkelijke tekst",
  "snoozeDescription": "Stop tijdelijk met het zien van berichten van {{name}}",
  "unfollowDescription": "Stop met het zien van berichten van {{name}}",
  "reportDescription": "Heb je een probleem met deze post?",
  "deleteDescription": "Deze post permanent verwijderen?"
};