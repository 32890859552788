export default {
  "yes": "Ja",
  "no": "Nee",
  "ok": "OKÉ",
  "cancel": "Annuleer",
  "hour": "Uur",
  "hours": "Uren",
  "week": "Week",
  "month": "Maand",
  "year": "Jaar",
  "tyCard": "Bedankkaart",
  "tyCards": "Bedankkaarten",
  "send": "Verzenden",
  "public": "Publiek",
  "private": "Privé",
  "like": "Zoals",
  "likes": "Houdt van",
  "comment": "Commentaar",
  "comments": "Opmerkingen",
  "reply": "Beantwoorden",
  "share": "Delen",
  "person": "persoon",
  "people": "mensen",
  "selected": "geselecteerd",
  "search": "Zoek",
  "errors": {
    "errorLoadingData": "Fout bij het laden van gegevens",
    "errorLoadingPage": "Fout bij het laden van de pagina",
    "errorReload": "Fout bij het laden van het menu, laad de pagina opnieuw"
  },
  "success": {
    "general": "De actie is geslaagd"
  },
  "noData": "Het lijkt erop dat er nog niets is!",
  "noDataDescription": "Kom later terug om updates te zien.",
  "members": "Leden",
  "translate": "Vertalen",
  "seeOriginal": "Origineel bekijken"
};