import initState from './initState';
import { FEATURES_STATE, FeaturesActionTypes } from './actionTypes';
import objectAssign from 'object-assign';
import { FeaturesReducerState } from '../../../types/types';

export default function FeaturesReducer(
    state: FeaturesReducerState = initState,
    action: FeaturesActionTypes
){
    switch(action.type){
        case FEATURES_STATE:
            return objectAssign({}, state, action.payload);
        default:
            return state;
    }
}