export default {
  "repliedToYourComment": "J'ai répondu à votre commentaire",
  "likedYourComment": "J'ai aimé ton commentaire",
  "sentYou": "Je vous ai envoyé {{text, en-handle-an}}",
  "received": "Reçu {{text, en-handle-an}}",
  "sendEmail": "Courrier électronique",
  "sendMessage": "Message",
  "createThread": "Créer un nouveau fil",
  "attachments": "Pièces jointes",
  "done": "Terminé",
  "expand": "Élargir",
  "hide": "Masquer",
  "showMoreComments": "Afficher plus de commentaires",
  "hideComments": "Masquer les commentaires",
  "showComments": "Afficher les commentaires",
  "hideReplies": "Masquer les réponses",
  "showReplies": "Afficher les réponses",
  "deleteComment": "Supprimer le commentaire",
  "deleteCommentDesc": "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
  "noCommentsYet": "Il n'y a pas encore de commentaires...",
  "chooseAction": "Choisissez une action",
  "deletePost": "Supprimer",
  "deletePostDesc": "Êtes-vous sûr de vouloir supprimer ce message ?",
  "unfollow": "Ne plus suivre",
  "snooze": "Snooze",
  "reportPost": "Publication du rapport",
  "report": "Rapport",
  "reportReason": "Raison du signalement de cette publication",
  "messageToManager": "Message au responsable",
  "delete": "Supprimer",
  "emptyAwardsMessage": "Il n'y a rien à afficher ici pour le moment car vous n'avez encore rien reçu !",
  "Followers": "Abonnés",
  "Following": "Suivant",
  "SendAThankYou": "Envoyer {{label, en-handle-an}}",
  "SeeAll": "Tout voir",
  "SentToYou": "Envoyé à vous",
  "CreateASocialPost": "Créez une publication sur les réseaux sociaux",
  "Photo": "Photo",
  "Video": "Vidéo",
  "Files": "Dossiers",
  "Post": "Publier",
  "LatestComments": "Derniers commentaires",
  "SentBy": "Envoyé Par",
  "AllPosts": "Tout",
  "MyGroups": "Mes Groupes",
  "Search": "Chercher...",
  "SpecificGroup": "Groupe Spécifique",
  "selectGroupToPost": "Sélectionnez un groupe pour publier...",
  "cannotPostAlert": "Il semble que vous n'ayez pas la permission de poster dans ce groupe. Veuillez sélectionner un autre groupe.",
  "translateDescription": "Traduire dans votre langue préférée",
  "seeOriginalDescription": "Voir le texte original",
  "snoozeDescription": "Arrêter temporairement de voir les publications de {{name}}",
  "unfollowDescription": "Arrêter de voir les publications de {{name}}",
  "reportDescription": "Vous avez un problème avec cette publication ?",
  "deleteDescription": "Supprimer définitivement cette publication ?"
};