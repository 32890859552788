export default {
  "yes": "Oui",
  "no": "Non",
  "ok": "OK.",
  "cancel": "Annuler",
  "hour": "Heure",
  "hours": "Heures",
  "week": "Semaine",
  "month": "Mois",
  "year": "Année",
  "tyCard": "Carte de remerciement",
  "tyCards": "Cartes de remerciement",
  "send": "Envoyer",
  "public": "Publique",
  "private": "Privé",
  "like": "Comme",
  "likes": "J'aime",
  "comment": "Commenter",
  "comments": "Commentaires",
  "reply": "Réponse",
  "share": "PARTAGEZ",
  "person": "la personne",
  "people": "personnes",
  "selected": "sélectionné",
  "search": "Rechercher",
  "errors": {
    "errorLoadingData": "Erreur lors du chargement des données",
    "errorLoadingPage": "Erreur lors du chargement de la page",
    "errorReload": "Erreur lors du chargement du menu, veuillez recharger la page"
  },
  "success": {
    "general": "L'action a été un succès"
  },
  "noData": "On dirait qu'il n'y a encore rien ici !",
  "noDataDescription": "Revenez plus tard pour voir les mises à jour.",
  "members": "Membres",
  "translate": "Traduire",
  "seeOriginal": "Voir l'original"
};