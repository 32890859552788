import React from 'react';
import { Box, Popover, Tooltip } from '@mui/material';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile, faCircleEllipsis, faReply, IconDefinition, faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faGlobe as faSolidGlobe } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { TranslateBtnWrapper } from '../translateBtnWrapper/translateBtnWrapper';

export type ChatBubbleReactionsProps = {
    displayReactions: boolean;
    handleEmojiClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleEmojiClose: () => void;
    handleEllipsisClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleEllipsisClose: () => void;
    handleMessageDelete: () => void;
    id: string | undefined;
    messageID: string;
    ellipsisId: string | undefined;
    openEllipsis: boolean;
    open: boolean;
    emojiAnchorEl: HTMLButtonElement | null;
    ellipsisAnchorEl: HTMLButtonElement | null;
    onEmojiClick: (event: any, emojiObject: any) => void;
    primary: boolean;
    onReplyToClick?: () => void;
    allowReactions: boolean;
    isFromSendMessagePanel?: boolean;
    handleTranslateClick?: (id: number, clicked: boolean) => void;
    clickedTranslateTextObj?: {
        [key: number]: boolean | null;
    };
};

export const ChatBubbleReactions = (props: ChatBubbleReactionsProps) => {
    const {
        displayReactions,
        handleEmojiClick,
        handleEmojiClose,
        id,
        ellipsisId,
        messageID,
        open,
        openEllipsis,
        allowReactions,
        emojiAnchorEl,
        onEmojiClick,
        primary,
        onReplyToClick,
        isFromSendMessagePanel = false,
        handleEllipsisClick,
        handleEllipsisClose,
        handleMessageDelete,
        ellipsisAnchorEl,
        handleTranslateClick,
        clickedTranslateTextObj,
    } = props;

    const { t } = useTranslation();

    const baseStyle = {
        display: displayReactions ? 'flex' : 'none',
        gap: '10px',
        fontSize: isFromSendMessagePanel ? '24px' : '20px',
        alignItems: 'center',
        marginTop: isFromSendMessagePanel ? '0px' : '8px',
    };

    const reactionOrder = primary
        ? isFromSendMessagePanel
            ? [faFaceSmile]
            : [faCircleEllipsis, faReply, faFaceSmile]
        : isFromSendMessagePanel
        ? [faFaceSmile]
        : [faFaceSmile, faReply, clickedTranslateTextObj?.[parseInt(messageID)] ? faSolidGlobe : faGlobe];

    const handleReactionClick = (e: any, reaction: IconDefinition) => {
        if (reaction === faFaceSmile) handleEmojiClick(e);
        if (reaction === faReply && onReplyToClick) onReplyToClick();
        if (reaction === faCircleEllipsis) handleEllipsisClick(e);
        if (reaction === faGlobe || faSolidGlobe) {
            handleTranslateClick && handleTranslateClick(parseInt(messageID), reaction === faGlobe);
        }
    };

    const RenderReaction = ({ reaction }: { reaction: IconDefinition }) => {
        return (
            <Tooltip
                title={reaction === faGlobe || reaction === faSolidGlobe ? t(reaction === faGlobe ? 'general.translate' : 'general.seeOriginal') : ''}
            >
                <span
                    onClick={(e) => {
                        handleReactionClick(e, reaction);
                    }}
                    className={`c-pointer ${isFromSendMessagePanel ? 'd-flex align-items-center' : ''}`}
                    data-testid={'reaction-' + reaction.iconName}
                >
                    <FontAwesomeIcon icon={reaction} />
                </span>
            </Tooltip>
        );
    };

    return (
        <Box sx={{ ...baseStyle }} data-testid='chat-bubble-reactions'>
            {reactionOrder.map(
                (reaction, index) =>
                    (reaction === faFaceSmile ? allowReactions : true) && (
                        <React.Fragment key={index}>
                            {reaction === faGlobe || reaction === faSolidGlobe ? (
                                <TranslateBtnWrapper>
                                    <RenderReaction reaction={reaction} />
                                </TranslateBtnWrapper>
                            ) : (
                                <RenderReaction reaction={reaction} />
                            )}
                        </React.Fragment>
                    )
            )}
            <Popover
                open={open}
                anchorEl={emojiAnchorEl}
                onClose={handleEmojiClose}
                id={id}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: primary ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: primary ? 'right' : 'left',
                }}
                sx={{ display: displayReactions ? 'block' : 'none' }}
            >
                <EmojiPicker
                    onEmojiClick={onEmojiClick}
                    height={'300px'}
                    emojiStyle={EmojiStyle.NATIVE}
                    data-testid='emoji-picker'
                    previewConfig={{
                        showPreview: false,
                    }}
                />
            </Popover>
            <Popover
                open={openEllipsis}
                anchorEl={ellipsisAnchorEl}
                onClose={handleEllipsisClose}
                id={ellipsisId}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: primary ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: primary ? 'right' : 'left',
                }}
                sx={{ display: displayReactions ? 'block' : 'none' }}
                className='thread-options'
            >
                <Box
                    sx={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        padding: '5px',
                        gap: '5px',
                    }}
                    onClick={() => {
                        handleMessageDelete();
                        handleEllipsisClose();
                    }}
                >
                    <span className='fa-regular fa-trash-can' style={{ lineHeight: 1.4 }} />
                    <span data-testid='ellipsis-delete'>Delete</span>
                </Box>
            </Popover>
        </Box>
    );
};
