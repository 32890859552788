import React, { FunctionComponent, useEffect, useState } from 'react';
import Spinner from '../../../common/spinnerLoad/spinnerLoad';
import SocialPostsService from '../../../../services/socialPostsService';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import commentsController from '../../../../services/commentsService';
import socialPostsService from '../../../../services/socialPostsService';
import objectAssign from 'object-assign';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';

import SocialPostCommentItem from './socialPostCommentItem';
import SocialPostCommentInput from './socialCommentInput';
import classnames from 'classnames';
import { fileData } from '../../createSocialPost';
import { useTranslateQuery } from '../../../../hooks/query/useTranslateQuery';

type SocialPostCommentsProps = {
    item: any;
    setItem: Function;
    addReplyOpen: boolean;
    setReplyOpen: Function;
};

const SocialPostComments: FunctionComponent<SocialPostCommentsProps> = ({ item, setItem, addReplyOpen, setReplyOpen }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loadingComments, setLoadComments] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [comments, setComments] = useState<Array<any>>([]);
    const [showRepliesTo, setShowReplies] = useState<Array<number>>([]);
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [idToTranslate, setIdToTranslate] = useState<Element | null>(null);
    const [translatedCommentText, setTranslatedCommentText] = useState<{
        [key: number]: {
            originalText: string;
            translatedText: string;
        };
    }>({});
    const [clickedTranslateText, setclickedTranslateText] = useState<{
        [key: number]: boolean | null;
    }>({});

    const { isLoading: translatingText } = useTranslateQuery(
        Number(idToTranslate?.getAttribute('data-id')),
        'comment',
        languagetoTranslate,
        (response) => {
            if (!response || !idToTranslate) return;
            setTranslatedCommentText((prev) => {
                return {
                    ...prev,
                    [Number(idToTranslate.getAttribute('data-id'))]: {
                        originalText: idToTranslate.innerHTML,
                        translatedText: response.data.translated_text,
                    },
                };
            });
            setIdToTranslate(null);
        }
    );

    const handleTranslate = (commentEl: Element, translateText: boolean) => {
        let commentID = Number(commentEl.getAttribute('data-id'));
        //when see original btn clicked
        if (!translateText) {
            setTranslatedCommentText((prev) => {
                return {
                    ...prev,
                    [commentID]: {
                        originalText: commentEl.innerHTML,
                        translatedText: prev[commentID] ? prev[commentID].translatedText : '',
                    },
                };
            });
            setclickedTranslateText((prev) => {
                return {
                    ...prev,
                    [commentID]: translateText,
                };
            });
            return;
        }
        //when translate btn clicked
        setIdToTranslate(commentEl);
        //create original text if not exists
        if (!translatedCommentText[commentID]) {
            setTranslatedCommentText((prev) => {
                return {
                    ...prev,
                    [Number(commentEl.getAttribute('data-id'))]: {
                        originalText: commentEl.innerHTML,
                        translatedText: '',
                    },
                };
            });
        }
        setclickedTranslateText((prev) => {
            return {
                ...prev,
                [Number(commentEl.getAttribute('data-id'))]: translateText,
            };
        });
    };

    const itemData = item.model || item;

    // index is '-1' for 'last_comment" item in post object
    const renderComment = (comment: any, index: string, rootId: number, childCount: number = 0) => {
        return (
            <SocialPostCommentItem
                key={comment.id}
                comment={comment}
                childCount={childCount}
                showRepliesTo={showRepliesTo}
                likeComment={likeComment}
                removeComment={removeComment}
                handleRepliesClick={handleRepliesClick}
                sendReply={leaveComment}
                rootId={rootId}
                index={index}
                handleTranslate={handleTranslate}
                commentId={comment.id}
                clickedTranslateTextObj={clickedTranslateText}
                translatedCommentText={translatedCommentText}
                translatingText={translatingText}
                idToTranslate={idToTranslate}
            />
        );
    };

    const removeComment = (comment: any) => {
        setComments((comments) => comments.filter((item) => item.id !== comment.id));

        if (comment.comment_id) {
            /**
             *  Item is a comment reply.. check if dealing with the last comment
             */

            if (itemData.last_comment && itemData.last_comment.id == comment.comment_id) {
                itemData.last_comment.replyCount--;
                itemData.last_comment.subcomments = itemData.last_comment.subcomments.filter((subcomment: any) => subcomment.id !== comment.id);
                renderComment(itemData.last_comment, '-1', -1);
            }
            /**
             *  Now just force a re-render
             */
            let newItem = objectAssign({}, item);
            setItem(newItem);
        } else {
            /**
             * Slightly kludgy - below represents pre-render conditions, so indices used reflect that.
             * There may be a better way of doing this, but for now this works ;-)
             */
            let newItem = objectAssign({}, item);

            if (comments.length === 1) {
                // If deleted 'last_comment' - just remove it
                if (item.model) {
                    newItem.model = objectAssign({}, newItem.model, { last_comment: null, comments: 0 });
                } else {
                    newItem = objectAssign({}, newItem, { last_comment: null, comments: 0 });
                }
            } else {
                // Refresh comments list
                /**
                 * Make sure last_comment is updated correctly
                 */
                if (
                    (item.last_comment && item.last_comment.id == comment.id) ||
                    (item.model && item.model.last_comment && item.model.last_comment.id == comment.id)
                ) {
                    if (item.model) {
                        newItem.model = objectAssign({}, newItem.model, {
                            last_comment: comments[comments.length - 2],
                            comments: itemData.comments - 1,
                        });
                    } else {
                        newItem = objectAssign({}, newItem, { last_comment: comments[comments.length - 2], comments: itemData.comments - 1 });
                    }
                } else {
                    if (item.model) {
                        newItem.model = objectAssign({}, newItem.model, { comments: itemData.comments - 1 });
                    } else {
                        newItem = objectAssign({}, newItem, { comments: itemData.comments - 1 });
                    }
                }
            }
            setItem(newItem);
        }
    };

    const leaveComment = (dataEl: Element | null, message: string, parentCommentId: number | null, files = []) => {
        if (!message && files.length == 0) {
            return;
        }
        const id = itemData.post_id ? itemData.post_id : itemData.id;
        socialPostsService.leaveComment(
            id,
            message.trim(),
            parentCommentId,
            files,
            (resp: AxiosResponse) => {
                const newComment = resp.data.data;
                if (!dataEl) {
                    // send comment
                    if (comments.length != 0) {
                        setComments([...comments, newComment]);
                    }
                    setReplyOpen(false);

                    let newItem = objectAssign({}, item);
                    if (item.model) newItem.model = objectAssign({}, newItem.model, { last_comment: newComment, comments: itemData.comments + 1 });
                    else newItem = objectAssign({}, newItem, { last_comment: newComment, comments: itemData.comments + 1 });

                    setItem(newItem);
                } else {
                    // send reply
                    changeCommentItem(dataEl, (comment: any) => {
                        comment.subcomments.push(newComment);
                    });
                }
            },
            () => {
                dispatch(addNotification({ label: `Post Comment`, text: t('general.errors.errorLoadingData'), type: 'danger' }));
            }
        );
    };

    const changeCommentItem = (commentEl: Element, callback: Function) => {
        const root = Number(commentEl.getAttribute('data-root'));
        const index = commentEl.getAttribute('data-index') || '';

        // index '-1' if item is the 'last_comment' of post
        if (index === '-1') {
            callback(itemData.last_comment);
            setItem(item);
        } else if (root === -1) {
            // comment is a subcomment of 'last_comment' object
            const indexArr = index.split('/');
            indexArr.shift(); // remove first el
            let childrenRow = itemData.last_comment.subcomments;
            let childItem: any = null;
            indexArr.forEach((indexItem, index) => {
                if (index === indexArr.length - 1) childItem = childrenRow[Number(indexItem)];
                else {
                    childrenRow = childrenRow[Number(indexItem)].subcomments;
                }
            });
            if (childItem) {
                callback(childItem);
                setItem(item);
            }
        } else {
            const newComments = [...comments];
            if (!isNaN(Number(index))) {
                // root item
                callback(newComments[Number(index)]);
            } else {
                // child item
                const indexArr = index.split('/');
                let childrenRow = newComments[Number(indexArr.shift())].subcomments;
                let childItem: any = null;
                indexArr.forEach((indexItem, index) => {
                    if (index === indexArr.length - 1) childItem = childrenRow[Number(indexItem)];
                    else {
                        childrenRow = childrenRow[Number(indexItem)].subcomments;
                    }
                });
                callback(childItem);
            }
            setComments(newComments);
        }
    };

    const likeComment = (commentEl: Element, isLiked: boolean) => {
        const commentId = Number(commentEl.getAttribute('data-id'));
        commentsController.likeComment(
            commentId,
            isLiked,
            (resp: AxiosResponse) => {
                changeCommentItem(commentEl, (item: any) => {
                    item.is_liked = isLiked;
                    item.like_count += isLiked ? 1 : -1;
                });
            },
            () => {
                dispatch(addNotification({ label: `Like/Dislike Comment`, text: t('general.errors.errorLoadingData'), type: 'danger' }));
            }
        );
    };

    const handleRepliesClick = (commentId: number, add: boolean) => {
        if (add) setShowReplies([...showRepliesTo, commentId]);
        else setShowReplies(showRepliesTo.filter((repId) => repId != commentId));
    };

    const loadComments = () => {
        setLoadComments(true);
        const id = itemData.post_id ? itemData.post_id : itemData.id;
        SocialPostsService.loadPostComments(
            id,
            (resp: AxiosResponse) => {
                setComments(resp.data.data);
                setLoadComments(false);
            },
            () => {
                dispatch(addNotification({ label: `Load Comments`, text: t('general.errors.errorLoadingData'), type: 'danger' }));
                setLoadComments(false);
            }
        );
    };

    const renderExpandBtn = () => {
        const classes = 'c-pointer text-primary d-block text-end mt-1';
        if (comments.length > 0 && expanded)
            return (
                <span data-testid='hide-comments' className={classes} onClick={() => setExpanded(false)}>
                    {t('feed.hideComments')}
                </span>
            );
        else
            return (
                <span data-testid='show-comments' className={classes} onClick={() => setExpanded(true)}>
                    {t('feed.showComments')}
                </span>
            );
    };

    return (
        <React.Fragment>
            {addReplyOpen ? (
                <SocialPostCommentInput
                    className='mt-2'
                    onSubmit={(message: string, filesInfo: Array<fileData>) => leaveComment(null, message, null, filesInfo as any)}
                />
            ) : (
                ''
            )}

            {itemData.comments !== 0 ? (
                <div>
                    {itemData.comments > 1 ? (
                        comments.length === 0 ? (
                            <span
                                data-testid='show-more-comments-btn'
                                className={classnames(`${loadingComments ? 'text-center' : 'text-end'}`, 'c-pointer text-primary d-block mt-1')}
                                onClick={loadComments}
                            >
                                {loadingComments ? <Spinner size={15} className='py-2' /> : t('feed.showMoreComments')}
                            </span>
                        ) : (
                            renderExpandBtn()
                        )
                    ) : (
                        ''
                    )}

                    {comments.length === 0 || !expanded
                        ? itemData.last_comment
                            ? renderComment(itemData.last_comment, '-1', -1)
                            : ''
                        : comments.map((comment, index) => renderComment(comment, index.toString(), comment.id))}
                </div>
            ) : (
                <div />
            )}
        </React.Fragment>
    );
};

export default SocialPostComments;
