export default {
  "yes": "Ja",
  "no": "Nein",
  "ok": "OK",
  "cancel": "Stornieren",
  "hour": "Stunde",
  "hours": "Stunden",
  "week": "Woche",
  "month": "Monat",
  "year": "Jahr",
  "tyCard": "Dankeschön-Karte",
  "tyCards": "Dankeskarten",
  "send": "Senden",
  "public": "Öffentlich",
  "private": "Privat",
  "like": "Wie",
  "likes": "Gefällt mir",
  "comment": "Kommentar",
  "comments": "Kommentare",
  "reply": "Antworten",
  "share": "Teilen",
  "person": "Person",
  "people": "Menschen",
  "selected": "ausgewählt",
  "search": "Suche",
  "errors": {
    "errorLoadingData": "Fehler beim Laden der Daten",
    "errorLoadingPage": "Fehler beim Laden der Seite",
    "errorReload": "Fehler beim Laden des Menüs, bitte laden Sie die Seite neu"
  },
  "success": {
    "general": "Aktion war erfolgreich"
  },
  "noData": "Es sieht so aus, als wäre hier noch nichts!",
  "noDataDescription": "Schauen Sie später noch einmal vorbei, um Updates zu sehen.",
  "members": "Mitglieder",
  "translate": "Übersetzen",
  "seeOriginal": "Original anzeigen"
};