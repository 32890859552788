import React, { FunctionComponent, useEffect, useState } from 'react';
import { FeedSocialPost } from '../../../../../types/types';
import { useTranslation } from 'react-i18next';
import socialPostService from '../../../../services/socialPostsService';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';
import { useDispatch } from 'react-redux';
import './socialPostItem.scss';
import userService from '../../../../../web/services/usersService';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Popover, List, ListItemButton, Typography, ListItemText, ListItem } from '@mui/material';
import { IconText } from '../../../iconText/IconText';
import { TranslateBtnWrapper } from '../../../translateBtnWrapper/translateBtnWrapper';

export type FeedPostActionProps = {
    item: FeedSocialPost;
    removeItem: Function;
    canDeletePost: boolean;
    removeContentByUser?: Function;
    handleTranslate?: (id: number, clicked: boolean) => void;
    clickedTranslateTextObj: { [key: number]: boolean | null };
    open: boolean;
    handleClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
    anchorEl: HTMLButtonElement | null;
};

enum contextMenu {
    main,
    snooze,
    report,
}

const FeedPostActions: FunctionComponent<FeedPostActionProps> = ({
    item,
    removeItem,
    canDeletePost,
    removeContentByUser,
    handleTranslate,
    clickedTranslateTextObj,
    open,
    handleClose,
    anchorEl,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [reportText, setReportText] = useState('');
    const [menu, setMenu] = useState(contextMenu.main);
    const [displayDeleteAlert, setDisplayDeleteAlert] = useState<boolean>(false);

    const headerStyle = {
        fontSize: '16px',
        fontWeight: 600,
    };
    const subtitleStyle = {
        fontSize: '12px',
        fontWeight: 400,
    };
    const iconStyle = {
        fontSize: '20px',
        width: '22px',
        lineHeight: '1.2',
    };

    const deletePost = (e: any) => {
        e.stopPropagation();
        setDisplayDeleteAlert(true);
        confirmAlert({
            title: `${t('feed.deletePost')}`,
            message: `${t('feed.deletePostDesc')}`,
            buttons: [
                {
                    label: t('general.yes'),
                    onClick: () => {
                        socialPostService.deletePost(
                            item.model.id,
                            () => {
                                removeItem(item.model.id);
                                dispatch(
                                    addNotification({
                                        label: `Delete Post`,
                                        text: t('general.success.general'),
                                        type: 'success',
                                    })
                                );
                            },
                            () => {
                                dispatch(
                                    addNotification({
                                        label: `Delete Post`,
                                        text: t('general.errors.errorLoadingData'),
                                        type: 'danger',
                                    })
                                );
                            }
                        );
                        handleClose && handleClose(e, 'backdropClick');
                        setDisplayDeleteAlert(false);
                    },
                },
                {
                    label: t('general.no'),
                    onClick: () => {
                        handleClose && handleClose(e, 'backdropClick');
                    },
                },
            ],
            overlayClassName: 'zIndex1050',
        });
    };

    const unfollowHandler = () => {
        socialPostService.unfollowUser(
            item.model.author.id,
            () => {
                removeContentByUser && removeContentByUser(item.model.author.id);
                dispatch(
                    addNotification({
                        label: `Unfollow`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Unfollow`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const snoozeHandler = (snoozeMins: number) => {
        userService.snooze(
            snoozeMins,
            item.model.author.id,
            () => {
                removeContentByUser && removeContentByUser(item.model.author.id);
                dispatch(
                    addNotification({
                        label: `Snooze`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Snooze`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const sendReport = (e: any) => {
        e.stopPropagation();
        if (!reportText) return;
        socialPostService.reportPost(
            item.model.id,
            reportText,
            () => {
                setMenu(contextMenu.main);
                dispatch(
                    addNotification({
                        label: `Report`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Report`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const getDeleteOnly = () => {
        return (
            <List sx={{ p: 0 }}>
                <ListItemButton onClick={deletePost} data-testid='delete-btn'>
                    <IconText icon='fa-regular fa-trash-can' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.deletePost')}</Typography>
                        <Typography sx={subtitleStyle}>{t('feed.deleteDescription')}</Typography>
                    </IconText>
                </ListItemButton>
                <TranslateBtnWrapper>
                    <ListItemButton onClick={onTranslateClick} data-testid='translate-btn'>
                        <IconText icon='fa-regular fa-globe' text={''} iconStyle={iconStyle}>
                            <Typography sx={headerStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('general.seeOriginal') : t('general.translate')}
                            </Typography>
                            <Typography sx={subtitleStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('feed.seeOriginalDescription') : t('feed.translateDescription')}
                            </Typography>
                        </IconText>
                    </ListItemButton>
                </TranslateBtnWrapper>
            </List>
        );
    };

    const renderReport = () => {
        return (
            <List
                sx={{
                    padding: '5px',
                }}
            >
                <ListItemText data-testid='report-label'>{t('feed.reportReason')}</ListItemText>
                <ListItem sx={{ padding: '0 5px' }}>
                    <textarea
                        data-testid='report-textarea'
                        className='w-100 report-input'
                        rows={8}
                        onChange={reportInputHandler}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {reportText}
                    </textarea>
                </ListItem>
                <ListItem sx={{ justifyContent: 'center', gap: '10px' }}>
                    <Button
                        data-testid='report-cancel'
                        className='float-left cancel-button'
                        onClick={(e) => {
                            e.stopPropagation();
                            setMenu(contextMenu.main);
                        }}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button disabled={reportText === ''} data-testid='submit-report-button' className='float-right send-button' onClick={sendReport}>
                        {t('feed.report')}
                    </Button>
                </ListItem>
            </List>
        );
    };

    const reportInputHandler = (e: any) => {
        e.stopPropagation();
        const val = e.currentTarget.value.trim();
        setReportText(val);
    };

    const onTranslateClick = (e: any) => {
        e.stopPropagation();
        handleTranslate && handleTranslate(item.model.id, clickedTranslateTextObj[item.model.id] ? !clickedTranslateTextObj[item.model.id] : true);
    };

    const renderSnooze = () => {
        return (
            <List sx={{ p: 0 }}>
                <ListItemButton onClick={() => snoozeHandler(60 * 24 * 7)} data-testid='snooze-option'>
                    <ListItemText>{`1 ${t('general.week')}`}</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => snoozeHandler(60 * 24 * 7 * 4)} data-testid='snooze-option'>
                    <ListItemText>{`1 ${t('general.month')}`}</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => snoozeHandler(60 * 24 * 7 * 4 * 12)} data-testid='snooze-option'>
                    <ListItemText>{`1 ${t('general.year')}`}</ListItemText>
                </ListItemButton>
            </List>
        );
    };

    const getAllOptions = () => {
        return (
            <List sx={{ p: 0 }}>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenu(contextMenu.snooze);
                    }}
                    data-testid='snooze-button'
                    sx={{ padding: '16px 16px 8px 16px' }}
                >
                    <IconText icon='fa-regular fa-alarm-snooze' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.snooze')}</Typography>
                        <Typography sx={subtitleStyle}>
                            {t('feed.snoozeDescription', {
                                name: item.model.author.full_name,
                            })}
                        </Typography>
                    </IconText>
                </ListItemButton>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation();
                        unfollowHandler();
                    }}
                    data-testid='unfollow-button'
                    sx={{ padding: '8px 16px 8px 16px' }}
                >
                    <IconText icon='fa-regular fa-user-minus' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.unfollow')}</Typography>
                        <Typography sx={subtitleStyle}>
                            {t('feed.unfollowDescription', {
                                name: item.model.author.full_name,
                            })}
                        </Typography>
                    </IconText>
                </ListItemButton>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenu(contextMenu.report);
                    }}
                    data-testid='report-button'
                    sx={{ padding: '8px 16px 8px 16px' }}
                >
                    <IconText icon='fa-light fa-circle-exclamation' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.reportPost')}</Typography>
                        <Typography sx={subtitleStyle}>{t('feed.reportDescription')}</Typography>
                    </IconText>
                </ListItemButton>
                <TranslateBtnWrapper>
                    <ListItemButton onClick={onTranslateClick} data-testid='translate-btn' sx={{ padding: '8px 16px 16px 16px' }}>
                        <IconText icon='fa-regular fa-globe' text={''} iconStyle={iconStyle}>
                            <Typography sx={headerStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('general.seeOriginal') : t('general.translate')}
                            </Typography>
                            <Typography sx={subtitleStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('feed.seeOriginalDescription') : t('feed.translateDescription')}
                            </Typography>
                        </IconText>
                    </ListItemButton>
                </TranslateBtnWrapper>
            </List>
        );
    };

    useEffect(() => {
        setDisplayDeleteAlert(false);
        if (open) setMenu(contextMenu.main);
    }, [open]);

    return (
        <Popover
            open={open}
            onClose={(e: any) => {
                e.stopPropagation();
                handleClose && handleClose(e, 'backdropClick');
            }}
            sx={{
                display: displayDeleteAlert ? 'none' : 'block',
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            data-testid='feed-post-actions-modal'
        >
            <div data-testid='feed-post-actions'>
                {canDeletePost ? (
                    <div className={'fake-modal-content'}>{getDeleteOnly()}</div>
                ) : menu === contextMenu.main ? (
                    <div className={'fake-modal-content'}>{getAllOptions()}</div>
                ) : menu === contextMenu.report ? (
                    <div data-testid='report-popup' className={'fake-modal-content'}>
                        {renderReport()}
                    </div>
                ) : (
                    <div className={'fake-modal-content'}>{renderSnooze()}</div>
                )}
            </div>
        </Popover>
    );
};

export default FeedPostActions;
